 #header {
  position: absolute;
  width: 220px;
}

#header .closemenu {
  color: #000;
  position: absolute;
  right: 0;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  top: 55px;
  cursor: pointer;
}
#header .pro-sidebar {
  width: 12%;
  min-width: 0%;
  position: fixed;
  top: 0;
}
.img-thumbnail {
  padding: 0.25rem !important;
  border-radius: 0.25rem !important;
  max-width: 100% !important;
  height: auto !important;
  background-color: #26ae61 !important;
  /* border: 1px solid #26ae61 !important; */
}
.fixed {
  position: fixed;
  width: 100%;
  bottom: 0;
}

#header .pro-sidebar {
  height: -webkit-fill-available !important;
}
.imgs {
  height: 60px !important;
}

#header .pro-sidebar-inner {
  background-color: #26ae61;
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}
#header .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
}
#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 20px;
  padding: 0 20px;
  color: #000;
  font-weight: bold;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 5px;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: white;
  margin: 10px 20px;
  font-weight: bold;
}
.mt-10 {
  margin-top: 10rem !important;
}
.he {
  background: linear-gradient(to left, #ffc107, #26ae61) !important;
  color: #fff !important;
}
#header
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper {
  background-color: #26ae61;
  color: white;
  zoom: 1.5;
  border-radius: 3px;
}
#header
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper
  .pro-item-content {
  color: #000;
}

#header .pro-sidebar-inner .pro-sidebar-layout .active {
  height: 69px;
  background-color: #fff;
  color: #26ae61 !important;
  width: 94px;
  border-radius: 1rem;
}
#header .logo {
  padding: 20px;
}

@media only screen and (max-width: 720px) {
  html {
    overflow: hidden;
  }
}
.color1 {
  color: #16a34a;
  text-shadow: 0px 2px 2px grey;
  font-family: inherit;
}
.br-1 {
  border-radius: 1.25em !important ;
}

.mini-sidebar {
  display: none;
}
.sidebar {
  flex: 0.4;
  padding: 5px;
  box-shadow: 0 0 10px #d8d3d3;
  height: 100%;
  width: 15%;
  position: fixed;
  left: auto;
  top: 30px;
  margin-top: 20px;
  background-color: #fff;
  margin-top: 5vh;
}
@media (max-width: 767px) {
  .sidebar {
    top: 12px;
  }
}
@media (max-width: 700px) {
  .sidebar {
    display: none;
  }
  .mini-sidebar {
    display: inherit;
    top: 30px;
    left: 1%;
    margin-top: 20px;
    position: fixed;
    height: 100%;
    width: 30% !important;
  }
}

.sidebar-option {
  margin-top: 10px;
  height: fit-content;
  display: flex;
  padding-bottom: 5px;
  border: 1px solid #ccbcbc;
  border-radius: 20px;
  width: 80%;
  cursor: pointer;
  color: #1a1919;
}
.sidebar-active {
  background-color: #1c065e;
  border-radius: 20px;
  color: #f01cf0;
}
.sidebar-active h2 {
  color: #f01cf0;
}
.sidebar-icon {
  margin: 0 !important;
  color: red !important;
  align-self: center;
  margin-top: 10% !important;
  font-size: medium;
  padding: 5px;
}

.sidebar-option:hover {
  background-color: #cfd7dd;
  border-radius: 20px;
  transition: color 100ms ease-out;
}
.sidebar-option > h2 {
  font-size: medium;
  font-weight: 600;
  margin-right: 20px;
  padding-top: 26px;
}
#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}
@import "~antd/dist/antd.css";

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
} 

