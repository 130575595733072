*{
  font-family: "OpenSans";
  
}
@font-face {
  font-family: "OpenSans";
  src: url("../asset/OpenSans/OpenSans-Bold.ttf");
}
.banner-1 {
  /* background-image: url("../asset/images/bg-slider/02.jpg"); */
  background-color: #000;
  height: 790px !important;
}
.margin_top{
  margin-top: 250px !important;
  /* border: 2px solid red; */
}
.bg-theme {
  background-color: #26ae61 !important;
}
.navbar-theme .navbar-nav .nav-link {
  color: white !important;
}
.navbar-theme .navbar-nav .nav-link:hover,
.navbar-theme .navbar-nav .nav-link:focus {
  color: #000 !important;
}
.text-right {
  text-align: right !important;
}

.font {
  font-family: proxima-nova, sans-serif;
}
.bg_blue{
  background-color: #47A6E2 !important;
}

.btn.btn-lg {
  padding: 14px 24px !important;
  /* color: #16a34a !important; */
  font-weight: bolder;
  border-radius: 0;
  /* border: 1px solid #fff; */
  width: 100%;
  border: 0px;
}
.btn.btn-lg:hover {
  color: white !important;
}
.btn.btn--white {
  background-color: #fff;
}
.btn.btn--white:hover {
  color: #26ae61 !important;
}

.pl-50 {
  padding-left: 50px;
}
.position {
  width: 50%;
  position: relative;
  right: -60px;
}
.authheader {
  background: #26ae61;
  z-index: 10;
  position: relative;
  width: 100%;
}
.shadow {
  box-shadow: 0 25.5rem 101rem 107rem rgb(0 0 0 / 15%) !important;
}
.color {
  color: whitesmoke;
  text-shadow: 0px 2px 2px #16a34a;
  font-family: inherit;
}
/* .error{
    background-color: #16a34a;
    color: red;
    font-family: inherit;
    font-weight: bolder;
    border-radius: 20px;
    padding: 10px 0px 10px 0 ;
    text-align: center;
    
} */
/* .bg-login {
  min-height: 100vh !important;
  height: auto;
  overflow: hidden !important;
  background-repeat: no-repeat;
  background: url("../asset/images/bg-slider/03.jpg");
  background-size: cover;
} */
/* .fixed {
  position: fixed;
  bottom: 0;
} */
.py-1-7 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.border-radious {
  border-radius: 6% !important;
}
.text-theme {
  color: #16a34a !important;
}
.banner-land {
  background-image: url("../asset/images/landlord.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.searchbar {
  margin-bottom: auto;
  margin-top: auto;
  height: 60px;
  background-color: #fff;
  border-radius: 30px;
  padding: 10px;
}
.detailsearchbar {
  margin-bottom: auto;
  margin-top: auto;
  height: 60px;
  background-color: #fff;
  /* border-radius: 30px; */
  padding: 4px 10px;
}

.search_input {
  color: black;
  border: 0;
  outline: 0;
  background: none;
  width: 77%;
  caret-color: transparent;
  line-height: 40px;
  transition: width 0.4s linear;
}
.result {
  margin-bottom: auto;
  margin-top: auto;
  /* height: 60px; */
  background-color: #fff;
  border-radius: 30px;
  padding: 10px;
}

.result_input {
  color: black;
  border: 0;
  outline: 0;
  background: none;
  /* width: 77%; */
  caret-color: transparent;
  line-height: 40px;
  transition: width 0.4s linear;
}
.scroll {
  /* overflow-y: scroll; */
  overflow-x: hidden;
  height: 15rem;
}
.res {
  margin-left: 26px !important;
}
.mt-6 {
  margin-top: 4rem !important;
}
.search_icon {
  height: 40px;
  width: 40px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #000;
  text-decoration: none;
}
a {
  color: none !important;
  text-decoration: none !important;
}
a:hover {
  color: none !important;
  text-decoration: none !important;
}
/* width */
.scroll::-webkit-scrollbar {
  width: 10px;
}
.my-5rem {
  margin-top: 6rem !important;
}

/* Track */
.scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.scroll::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 10px;
}

/* Handle on hover */
.scroll::-webkit-scrollbar-thumb:hover {
  background: darkgreen;
}
/* .navbar .navbar-nav .nav-link:hover {
  color: #000 !important;
  background-color: #fff;
  font-weight: bold;
} */
.navbar .navbar-nav .nav-link {
  color: white !important;
  /* background-color: #fff; */
  font-weight: bold;
}
/* .white{
  color: #fff !important;
} */
@media (max-width: 991px) {
  .xsmall {
    background-color: #fff !important;
    color: #000 !important;
  }
  .navbar .navbar-nav .nav-link:hover {
    color: #209151 !important;
    background-color: #fff;
    font-weight: bold;
  }
  .cardi {
    box-shadow: 0 25.5rem 101rem 107rem rgb(0 0 0 / 15%) !important;
  }
  .cardi:hover {
    box-shadow: 2px 2px 2px 2px red !important;
  }
  a {
    outline: medium none !important;
    color: #16a34a !important;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
  }
}
.posi {
  position: fixed;
  top: 15%;
  display: inline-block !important;
}
/* Side Bar css */
.sidebar {
  /* min-height: 100%; */
  display: inline-block !important;
  height: 41rem;
  width: 4.2rem;
  background-color: #26ae61;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: 0.3s width;
}

.boxicon-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  position: relative;
}

.boxicon {
  margin: 0.8rem;
  animation-iteration-count: 1;
  z-index: 2;
}

.first-and-last-trash-fix {
  margin: 0.8rem;
}

/* .active {
  background-color: red;
} */

.active::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 95%;
  /* background-color: #373737; */
  opacity: 1;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  left: -0.25rem;
  border-top-right-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
}

.boxicon::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 95%;
  /* background-color: #373737; */
  opacity: 0;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  left: -0.25rem;
  border-top-right-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
}

.boxicon-container:hover > .boxicon::after {
  opacity: 1;
  transition: 0.1s;
}

.sidebar > *:first-child {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.sidebar > *:last-child {
  margin-top: auto;
  margin-bottom: 2rem;
}

.final-icon {
  background-color: red;
}

.description {
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5rem;
  z-index: 10;
  font-weight: bold;
  margin: 0;
  color: black;
  transition: 0.2s opacity;
}

.active-description {
  color: white;
}

.expanded {
  width: 13rem;
  align-items: flex-start;
}

.show-description {
  opacity: 1;
}

/* .container {
  display: flex;
} */

.text {
  color: white;
  z-index: 100;
  margin: 0 auto;
  align-self: center;
  font-size: 32px;
}

@media screen and (max-height: 550px) {
  .boxicon {
    margin-left: 1.2rem;
  }
  .first-and-last-trash-fix {
    margin-left: 1.2rem;
  }
}
.tab {
  position: absolute;
  top: 40%;
}
.front {
  z-index: 1;
}
.height {
  height: 100em;
}
.dropzone {
  padding: 10px;
  border: 2px dashed;
  /* align-items: center; */
  text-align: center;
}

.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  opacity: 1;
  /* background: url("../asset/images/Flexloader.gif") 50% 50% no-repeat rgba(225, 225, 225, 0.5); */
}

#app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.li {
  list-style: none;
  padding-left: 10px;
  border: 1px solid #26ae61 !important;
  margin-left: 5px;
  border-radius: 3px;
  padding: 5px;
  background-color: #26ae61;
  color: white;
}

.cardscroll {
  overflow: scroll;
  height: 81vh;
}
.detailheader {
  /* background: transparent */
  z-index: 10;
  position: relative;
  width: 100%;
}
.br-theme {
  border: 1px solid #26ae61 !important;
}
.round {
  border-radius: 1.25rem !important;
}
.mt-6 {
  margin-top: 4rem !important;
}
.fs-7 {
  font-size: 0.75rem !important;
}

.dropdown-menu.show {
  display: block !important;
}
.dropdown-item:hover {
  color: #1e2125;
  /* background-color: transparent !important; */
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  /* background-color: #26ae61 !important; */
}
.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 20.5rem !important;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #000 !important;
  text-align: left;
  list-style: none;
  background-color: transparent !important;
  background-clip: padding-box;
  border: 1px solid transparent !important;
  border-radius: 0;
}

#id_work_days {
  height: 50px;
  border: none;
  overflow: hidden;
  background: none;
}
#id_work_days::-moz-focus-inner {
  border: 0;
}
#id_work_days:focus {
  outline: none;
}
#id_work_days option {
  width: 40px;
  font-size: 1.2em;
  padding: 10px 0;
  text-align: center;
  margin-right: 10px;
  display: inline-block;
  cursor: pointer;
  border: rgb(236, 236, 233) solid 1px;
  border-radius: 5px;
  color: white;
}
.home-pb {
  padding: 0px 0 30px;
}
.bg-card {
  background-color: #00000085 !important;
  /* border: 2px solid white !important; */
}
.bg-theme-light {
  background-color: #B2B2B2;
}
/* .drop{
  width: 100%;
    height: 60px;
    border-radius: 0;
} */
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: white;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: white;
  border: 0;
}
.btlr {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.btrr {
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}
.border-20 {
  border: 20px solid #212529 !important;
}
.pos {
  top: 10px;
    width: 100%;
    position: relative;

}
.footerposition {
  position: fixed;
  bottom: 0;
}
.border-5 {
  border-width: 6px !important;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 300px !important;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}
hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  background-color: white !important;
}
.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #fafafa;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
  filter: invert(100);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%280, 0, 0, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
  filter: invert(100);
}
@media (max-width: 767px) and (min-width: 320px) {
  .mb {
    margin-bottom: 10px;
  }
  .mb-signup {
    margin-bottom: 5.5rem;
  }
}

.margin-200px {
  margin-left: 200px;
  transition: 0.5s;
}
.trans {
  transition: 0.5s;
}
.ms-14 {
  margin-left: 14rem;
}
.ms-15 {
  margin-left: 15rem;
}
.image-item {
  display: flex;
  margin: 10px 0;
  justify-content: center;
}
.image-item__btn-wrapper {
  display: flex;
  flex-direction: row;
  /* margin-left: 10px; */
  /* margin-top: 8%; */
  align-items: center;
  justify-content: end;
}
.uplaod {
  width: 100%;
  height: 14vh;
  border: 1px dashed black;
  /* border: black; */
}
.u-r {
  height: 12vh;
  margin-left: 5%;
  padding: 10px 15px;
}
.image {
  margin-right: 5%;
  width: 20%;
  object-fit: cover;
}
.height {
  height: 100vh;
}
/* listing detail page styling */

.main_listing_div {
  overflow-y: hidden;
  overflow: hidden;
  overscroll-behavior: hidden;
  overscroll-behavior: none;
}
.banner1img {
  height: 200px;
}
.newli {
  border: 0px !important;
  padding: 10px 5px !important;
}
.box_shadow {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}
.container_custom {
  max-width: 1000px;
  margin: 0 auto;
}
.margin_100 {
  margin-top: 75px;
}
@media (max-width: 768px) {
  .margin_100 {
    margin-top: 0px;
  }
}
@media (max-width: 992px) {
  .camera_main_div {
    top: 10px !important;
    right: 80px !important;
  }
}
@media (max-width: 766px) {
  .camera_main_div {
    top: 9px !important;
    right: 145px !important;
  }
  .verify_div{
    width: 90% !important;
  }
  
}
@media (max-width: 440px) {
  .camera_main_div {
    top: 9px !important;
    right: 105px !important;
  }
}
@media (max-width: 390px) {
  .camera_main_div {
    top: 9px !important;
    right: 70px !important;
  }
}
.border_radius {
  border-radius: 17px !important;
}
.input_focus:focus{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  transition: 0.5s !important;
  outline: none;
  border-color: #77797c !important;
}
/* 
.label_file{
  padding: 8px 10px;
  display: table;
  color: #000 !important;
  background-color: #fafafa;
  cursor: pointer;
  margin: 0 auto;
  border-radius: 5px;
  border: 1px dashed #0d6db7;
  height: 0px;
  transition: 0.3s;
  box-sizing: border-box;
  overflow: hidden;
}
.label_file:hover{
  cursor: pointer;
} */
/* input[type="file"] {
  display: none;
  border: 1px solid red;
} */

.camera_main_div {
  position: absolute;
  width: 45px;
  height: 45px;
  line-height: 43px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgb(0 24 128 / 10%);
  border-radius: 100%;
  color: #000;
  overflow: hidden;
  top: 20px;
    right: 122px;
    border: 1px solid black;
}
.camera_main_div:hover{
  cursor: pointer !important;
}
.img_main_div {
  position: relative;
}



/* new add profile image */




.avatar-wrapper {
  position: relative;
  height: 200px;
  width: 200px;
  margin: 50px auto;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 1px 1px 15px -5px black;
  transition: all 0.3s ease;
}
.avatar-wrapper:hover {
  transform: scale(1.05);
  cursor: pointer;
}
.avatar-wrapper:hover .profile-pic {
  opacity: 0.5;
}
.avatar-wrapper .profile-pic {
  height: 100%;
  width: 100%;
  transition: all 0.3s ease;
}
.avatar-wrapper .profile-pic:after {
  font-family: FontAwesome;
  content: "\f007";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  font-size: 190px;
  background: #ecf0f1;
  color: #34495e;
  text-align: center;
}
.avatar-wrapper .upload-button {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.avatar-wrapper .upload-button .fa-arrow-circle-up {
  position: absolute;
  font-size: 234px;
  top: -17px;
  left: 0;
  text-align: center;
  opacity: 0;
  transition: all 0.3s ease;
  color: #34495e;
}
.avatar-wrapper .upload-button:hover .fa-arrow-circle-up {
  opacity: 0.9;
}








.update-img {
  position: absolute;
  opacity: 0;
  z-index: 0;
  width: 100px;
  cursor: pointer;
  left: 0;
}
.profileheader_img{
  width: 30px;
  height: 30px;
  border-radius: 50%;
 margin-left: auto;
}
.profile_img{
  width: 130px;
  height: 130px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.btn-theme{
  background-color: #47a6e2 !important; 
  color: white !important;
}

.property_img{
  height: 100% !important;
  object-fit: cover;
}
.border_radius{
  border-radius: 1rem;
  border: 1px solid #DDDDDD;
}
.border_radius:hover{
  box-shadow: 0 0 0 2px #222222 !important;
border-color: transparent;
}
#radio{
  opacity: 0;
  width: 100%;
  height: 60px;
  /* display: none; */
}
#check{
  /* opacity: 0; */
  width: 100%;
  /* display: none; */
}
.cat{
  width: 86% !important; 
padding: 0 !important;
margin: 4px !important;
background-color: #16a34a !important;
border-radius: 4px !important;
border: 1px solid #fff !important;
overflow: hidden !important;
float: left !important;
}

.cat label {
float: left !important; line-height: 2.0em !important;
width: 10em !important; height: 2.0em !important;
}

.cat label span {
font-size: 0.75rem;
/* text-align: center !important; */
padding: 0px 7px !important;
display: block !important;
}

.cat label input {
position: absolute !important;
display: none !important;
color: #fff !important;
}
/* selects all of the text within the input element and changes the color of the text */
.cat label input + span{color: #fff !important;}


/* This will declare how a selected input will look giving generic properties */
.cat input:checked + span {
color: #ffffff !important;
text-shadow: 0 0  6px rgba(0, 0, 0, 0.8) !important;
}
.action input:checked + span{background-color: lightseagreen !important;}


.isActive{
  border: 2px solid #000;
}
.new1{
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}
/* .check_listing{
  padding: 10px;
  max-height: 100vh;
  overflow: auto;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.check_listing::-webkit-scrollbar{
  width: 7px;
}
.check_listing::-webkit-scrollbar-track{
  border-radius: 10px;
}
.check_listing::-webkit-scrollbar-thumb{
  border-radius: 10px;
  background-color: transparent;
  transition: 0.5s;
}
.check_listing:hover::-webkit-scrollbar-thumb{
  border-radius: 10px;
  background-color: #BCC0C4;
} */



.font_10{
  width: 20px;
}
input[type=checkbox] {
  transform: scale(1.5);
}
input[type=checkbox]:hover{
  cursor: pointer !important;
}

/* @media (max-width:425px){
  .logo{
    object-fit: cover;
    height: 40vh !important;
    position: absolute;
    right: 10px;
  }
  .toggle{
    position: absolute;
    right: 25px;
  }
} */
.new_logo{
  width: 12rem;
}
/* #price{
  margin-left: auto;
  height: 20vh;
  width: 30rem;
  margin-right: auto;
} */
/* #price::placeholder{
  color: #000;
font-size: 2rem;
font-weight: bolder;
position: absolute;
top: 0;
} */
@media (max-width: 500px) {
  .left_div{
    display: none;
  }
  .new_logo{
    width: 7rem;
  }
}
.custom_textarea{
  position: relative;
}
.custom_textarea::placeholder{
  position: absolute;
  /* border: 1px solid red; */
  color: #000;
font-size: 2rem;
font-weight: bolder;
top: -50px !important;
}
.policy{
  position: relative;
}
.policy::placeholder{
  position: absolute;
  /* border: 1px solid red; */
  color: #000;
font-size: 2rem;
font-weight: bolder;
}
.h-89vh{
  height: 89vh;
}
.Fixed{
  position: fixed;
  width: 50%;
}
.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 1rem;
}
.offcanvas-body {
  flex-grow: 1;
  padding: 3rem 3rem !important;
  overflow-y: scroll;
}
.text_404{
  font-size: 50px;
}
.object_fit_cover{
  object-fit: cover;
}
.property_page_img{
  height: 220px !important;
}
@media (min-width: 1800px) {
  .listing_detail_container{
    max-width: 75% !important;
  }
  .form_card_main{
    max-width: 85% !important;
    margin-left: auto;
    margin-top: 45px !important;
  }
}
.form_card_main{
  margin-top: 80px !important;
}
.searchImg{
  width: 10%;
  border-radius: 50%;
}
.desc{
  line-break: anywhere;
  
}
.page-scroll{
  height: 100vh;
  overflow-y: hidden;
}
.section-scroll{
  height: 100vh;
  overflow-y: scroll;

}
.h-16{
  height: 16vh;
}
.hight{
  max-height: 100vh;
  height: 100vh;
}
.sigCanvas{
  border: 2px solid black !important; 
}

.bg-black{
  background-color: #000 !important;
}
